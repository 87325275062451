var columns = [{
  title: '商品ID',
  dataIndex: 'managementid',
  key: 'managementid',
  width: '4%',
  scopedSlots: {
    customRender: 'managementid'
  }
}, {
  title: '商品类目',
  dataIndex: 'managementcategory',
  key: 'managementcategory',
  width: '10%',
  scopedSlots: {
    customRender: 'managementcategory'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  width: '10%',
  scopedSlots: {
    customRender: 'brandname'
  }
},
// {
//     title: '系列名称',
//     dataIndex: 'seriename',
//     key: 'seriename',
//     width: '10%',
//     scopedSlots: {
//         customRender: 'seriename'
//     }
// },
{
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  width: '10%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '5%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '商品名称',
  dataIndex: 'name',
  key: 'name',
  width: '8%',
  scopedSlots: {
    customRender: 'name'
  }
},
// {
//     title: '发售价($)',
//     dataIndex: 'saleprice',
//     key: 'saleprice',
//     width: '5%',
//     scopedSlots: {
//         customRender: 'saleprice'
//     }
// },
{
  title: '在售商家',
  dataIndex: 'count',
  key: 'count',
  width: '5%',
  scopedSlots: {
    customRender: 'count'
  }
}, {
  title: '是否上架',
  dataIndex: 'status',
  key: 'status',
  width: '5%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '10%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '上架/下架时间',
  dataIndex: 'updrowtime',
  key: 'updrowtime',
  width: '10%',
  scopedSlots: {
    customRender: 'updrowtime'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  // width: '8%',
  scopedSlots: {
    customRender: 'operation'
  }
}
// {
//     title: '操作',
//     key: 'action',
//     dataIndex: 'action',
//     fixed: "right",
//     width: 200,
//     scopedSlots: {
//         customRender: 'action'
//     }
// }
];

var recommendgoodscolumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  // width: '7%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '商品类目',
  dataIndex: 'managementcategory',
  key: 'managementcategory',
  // width: '11%',
  scopedSlots: {
    customRender: 'managementcategory'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  // width: '7%',
  scopedSlots: {
    customRender: 'brandname'
  }
},
// {
//     title: '系列名称',
//     dataIndex: 'seriename',
//     key: 'seriename',
//     // width: '7%',
//     scopedSlots: {
//         customRender: 'seriename'
//     }
// },
{
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '7%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  // width: '9%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}];
var emailcolumns = [{
  title: '商家名称',
  dataIndex: 'username',
  key: 'username',
  width: '25%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '商家类型',
  dataIndex: 'registertype',
  key: 'registertype',
  width: '25%',
  scopedSlots: {
    customRender: 'registertype'
  }
}, {
  title: '邮箱',
  dataIndex: 'email',
  key: 'email',
  width: '25%',
  scopedSlots: {
    customRender: 'email'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  // fixed: "right",
  // width: '18%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
export { columns, recommendgoodscolumns, emailcolumns };