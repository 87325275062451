var searchKeys = [{
  key: "parentcategoryid",
  label: "类目",
  placeholder: "一级类目",
  required: false,
  rules: [],
  select: true
}, {
  key: "categoryid",
  label: "",
  placeholder: "二级类目",
  required: false,
  rules: [],
  select: true
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "sextype",
  label: "款式",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
},
// {
//     key: "serieid",
//     label: "系列",
//     placeholder: "全部",
//     required: false,
//     rules: [],
//     select: true,
// },
{
  key: "status",
  label: "是否上架",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "sku",
  label: "货号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "name",
  label: "商品名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var recommendgoodssearchKeys = [{
  key: "parentcategoryid",
  label: "类目",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "categoryid",
  label: "",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "sku",
  label: "货号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];

// const addgoodssearchkeys = [
//     {
//         key: "categoryone",
//         label: "分类",
//         placeholder: "潮鞋",
//         required: false,
//         rules: [{required:true}],
//         select: true
//     },
//     {
//         key: "categorytwo",
//         label: "",
//         placeholder: "全部",
//         required: false,
//         rules: [{required:true}],
//         select: true
//     },
//     {
//         key: "seriesone",
//         label: "系列",
//         placeholder: "请选择系列",
//         required: false,
//         rules: [{required:true}],
//         select: true
//     },
//     {
//         key: "seriestwo",
//         label: "",
//         placeholder: "请选择系列",
//         required: false,
//         rules: [{required:true}],
//         select: true
//     },
//     {
//         key: "mainnumber",
//         label: "主货号",
//         placeholder: "请输入",
//         required: false,
//         rules: [{required:true}],
//         input: true
//     },
//     {
//         key: "mainnumber",
//         label: "暂无货号",
//         required: false,
//         rules: [{required:true}],
//         group: true,
//     },
//     {
//         key: "auxiliarynumber",
//         label: "辅助货号",
//         placeholder: "请输入",
//         required: false,
//         input: true
//     },
//     {
//         key: "name",
//         label: "名称",
//         placeholder: "请输入",
//         required: false,
//         input: true,
//         rules: [{required: true,}]
//     },
//     {
//         key: 'dates',
//         label: '提交时间',
//         format: "YYYY-MM-DD HH:mm",
//         placeholder: ["开始日期", "结束日期"],
//         dates: true,
//         required: false,
//         rules: [{required: true,}]
//     },
//     {
//         key: "sendprice",
//         label: "发售价($)",
//         placeholder: "请输入",
//         required: false,
//         input: true,
//         rules: [{required: true,}]
//     },
//     {
//         key: "images",
//         label: "商品图片",
//         placeholder: "",
//         required: false,
//         upload: true,
//         rules: [{required: true,}]
//     },
//     {
//         key: "size",
//         label: "尺码规格",
//         placeholder: "",
//         required: false,
//         rules: [{required:true}],
//         select: true
//     },
//     {
//         key: "color",
//         label: "颜色",
//         placeholder: "请输入",
//         required: false,
//         rules: [{required:true}],
//         input: true
//     },
//     {
//         key: "distribution",
//         label: "配送方式",
//         placeholder: "",
//         required: false,
//         rules: [{required:true}],
//         select: true
//     },
//     {
//         key: "salesreturn",
//         label: "是否支持退换货",
//         required: false,
//         rules: [{required:true}],
//         group: true,
//     },

//     {
//         key: "shelves",
//         label: "是否立即上架",
//         required: false,
//         rules: [{required:true}],
//         group: true,
//     },
// ]

export { searchKeys, recommendgoodssearchKeys };